import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import {
  Card, CardImg, CardBody, CardTitle, CardSubtitle,
} from "reactstrap";

const separator = {
  borderLeft: "1px solid #CCC",
};

const darkEmoji = {
  fontSize: "10pt",
};

const ListingCard = ({ listing, isLoading }) => (
  <>
    {!isLoading && (
      <Card color="light">
        <a
          href={`/inmueble?codpro=${listing.codpro}`}
          style={{ position: "relative" }}
        >
          <CardImg
            top
            height="240px"
            src={listing.images ? listing.images[0].imageurl : listing.image}
            alt={`propiedad en ${listing.biz}`}
          />
          <span style={{
            display: "block",
            bottom: "0",
            right: "0",
            position: "absolute",
            background: "rgba(181, 181, 181, 0.25)",
            left: "0",
            textAlign: "right",
            padding: ".5em",
            paddingRight: "1em",
            color: "#FFF",
            textShadow: "1px 1px 1px #444",
          }}
          >
            Ver m&aacute;s
          </span>
        </a>
        <CardBody>
          <CardTitle>
            <a
              href={`inmueble?codpro=${listing.codpro}`}
              className="text-dark"
            >
              <span className="text-capitalize">
                {`${listing.type}`.toLowerCase()}
              </span>
              <span> en </span>
              <span>{`${listing.biz}`.toLowerCase()}</span>
            </a>
          </CardTitle>
          <CardSubtitle>
            <span className="text-capitalize">{listing.neighborhood}</span>
            <span>
              , &nbsp;
              {`${listing.city}`.replace("Bogota", "Bogotá")}
            </span>
          </CardSubtitle>
          <small>
            C&oacute;digo: &nbsp;
            {listing.codpro}
          </small>
          <div className="mt-3">
            <small className="text-secondary">
              <span>Precio:</span>
            </small>
            <h5>{listing.price_format}</h5>
          </div>
          <div className="d-flex mt-4 w-100 justify-content-between">
            <span>
              <div>
                <small>
                  Area m
                  <sup>2</sup>
                </small>
              </div>
              <h5>
                <span role="img" aria-label="house" style={darkEmoji}>
                  🏠
                </span>
                {" "}
                {listing.area_cons}
              </h5>
            </span>
            <span className="pl-3" style={separator}>
              <div>
                <small>Hab</small>
              </div>
              <h5>
                <span role="img" aria-label="bedrooms" style={darkEmoji}>
                  🛏️
                </span>
                {" "}
                {listing.bedrooms}
              </h5>
            </span>
            <span className="pl-3" style={separator}>
              <div>
                <small>Ba&ntilde;os</small>
              </div>
              <h5>
                <span role="img" aria-label="shower" style={darkEmoji}>
                  🚽
                </span>
                {" "}
                {listing.bathrooms}
              </h5>
            </span>
            {listing.parking > 0 && (
              <span className="pl-3" style={separator}>
                <div>
                  <small>Garajes</small>
                </div>
                <h5>
                  <span role="img" aria-label="parking" style={darkEmoji}>
                    🚘
                  </span>
                  {" "}
                  {listing.parking}
                </h5>
              </span>
            )}
          </div>
        </CardBody>
      </Card>
    )}
  </>
);

ListingCard.propTypes = {
  isLoading: PropTypes.bool,
  listing: PropTypes.shape({}).isRequired,
};

ListingCard.defaultProps = {
  isLoading: false,
};

export default ListingCard;
