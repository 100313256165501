import React, { Component } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import {
  Container, Row, Col, Button, UncontrolledCollapse,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu,
  DropdownItem, Pagination, PaginationItem, PaginationLink,
} from "reactstrap";
import { Link } from "gatsby";
import range from "lodash/range";

import PageLayout from "../components/PageLayout";
import ListingCard from "../components/ListingCard";
import { getProGrid } from "../services/v2/domus";
import Spinner from "../components/Spinner";

class InmueblesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      inmuebles: [],

      // pagination
      currentPage: null,
      lastPage: null,
      from: null,
      to: null,

      // sorting
      order: null,

      // filters
      pvmin: null,
      pvmax: null,
      bedrooms: null,
      minparking: null,
      maxparking: null,
      minage: null,
      maxage: null,
      minbath: null,
      maxbath: null,
    };

    this.fetchListings = this.fetchListings.bind(this);
    this.filterBy = this.filterBy.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    // initial fetch
    this.fetchListings({ ...qs.parse(location.search) });
  }

  fetchListings(params) {
    this.setState({
      isLoading: true,
      inmuebles: [],
      totalInmuebles: 0,
    });

    getProGrid(params)
      .then((response) => {
        this.setState({
          inmuebles: response.data.data,
          totalInmuebles: response.data.total,
          currentPage: response.data.current_page,
          lastPage: response.data.last_page,
          from: response.data.from,
          to: response.data.to,
        });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  goToPage(event) {
    const { location } = this.props;
    const {
      order,
      pvmin,
      pvmax,
      pcmin,
      pcmax,
      bedrooms,
      minparking,
      maxparking,
      minage,
      maxage,
      minbath,
      maxbath,
    } = this.state;

    const qsParams = qs.parse(location.search);
    qsParams.page = event.target.value;

    // maintain order
    if (order) {
      qsParams.order = order;
    }

    // maintain filters
    if (pvmin || pvmax || bedrooms || minparking || maxparking
      || minage || maxage || minbath || maxbath || pcmin || pcmax
    ) {
      qsParams.pvmin = pvmin;
      qsParams.pvmax = pvmax;
      qsParams.bedrooms = bedrooms;
      qsParams.minparking = minparking;
      qsParams.maxparking = maxparking;
      qsParams.minage = minage;
      qsParams.maxage = maxage;
      qsParams.minbath = minbath;
      qsParams.maxbath = maxbath;
    }

    this.fetchListings(qsParams);
    window.scrollTo(0, 0);
  }

  filterBy(event) {
    const { location } = this.props;
    let {
      order,
      pvmin,
      pvmax,
      pcmin,
      pcmax,
      bedrooms,
      minparking,
      maxparking,
      minage,
      maxage,
      minbath,
      maxbath,
    } = this.state;

    const qsParams = qs.parse(location.search);
    qsParams.page = 1; // always return to first page when filtering

    // Sort by price
    if (event.target.name === "sortByPrice" || order) {
      order = qs.parse(event.target.value).order || order;
      qsParams.order = order;
      this.setState({ order });
      console.log("order is %s", order);
    }

    // filter by sale price
    if (event.target.name === "filterBySalePrice" || pvmin || pvmax) {
      pvmin = qs.parse(event.target.value).pvmin || pvmin;
      pvmax = qs.parse(event.target.value).pvmax || pvmax;
      qsParams.pvmin = pvmin;
      qsParams.pvmax = pvmax;
      this.setState({ pvmin, pvmax });
      console.log("price ranges from %s and %s", pvmin, pvmax);
    }

    // filter by rent price
    if (event.target.name === "filterByRentPrice" || pcmin || pcmax) {
      pcmin = qs.parse(event.target.value).pcmin || pcmin;
      pcmax = qs.parse(event.target.value).pcmax || pcmax;
      qsParams.pcmin = pcmin;
      qsParams.pcmax = pcmax;
      this.setState({ pcmin, pcmax });
      console.log("price ranges from %s and %s", pcmin, pcmax);
    }

    // filter by number of rooms
    if (event.target.name === "filterByRooms" || bedrooms) {
      bedrooms = qs.parse(event.target.value).bedrooms || bedrooms;
      qsParams.bedrooms = bedrooms;
      this.setState({ bedrooms });
      console.log("bedrooms: %s", bedrooms);
    }

    // filter by parking
    if (event.target.name === "filterByParking" || minparking || maxparking) {
      minparking = qs.parse(event.target.value).minparking || minparking;
      maxparking = qs.parse(event.target.value).maxparking || maxparking;
      qsParams.minparking = minparking;
      qsParams.maxparking = maxparking;
      this.setState({ minparking, maxparking });
      console.log("min parking: %s, max parking: %s", minparking, maxparking);
    }

    // filter by age
    if (event.target.name === "filterByAge" || minage || maxage) {
      minage = qs.parse(event.target.value).minage || minage;
      maxage = qs.parse(event.target.value).maxage || maxage;
      qsParams.minage = minage;
      qsParams.maxage = maxage;
      this.setState({ minage, maxage });
      console.log("min age: %s, max age: %s", minage, maxage);
    }

    // filter by bathrooms
    if (event.target.name === "filterByBathroom" || minbath || maxbath) {
      minbath = qs.parse(event.target.value).minbath || minbath;
      maxbath = qs.parse(event.target.value).maxbath || maxbath;
      qsParams.minbath = minbath;
      qsParams.maxbath = maxbath;
      this.setState({ minbath, maxbath });
      console.log("min bath: %s, max bath: %s", minbath, maxbath);
    }

    this.fetchListings(qsParams);
  }

  clearFilters() {
    const { location } = this.props;
    const qsParams = qs.parse(location.search);
    this.setState({
      pvmin: null,
      pvmax: null,
      pcmin: null,
      pcmax: null,
      bedrooms: null,
      minparking: null,
      maxparking: null,
      minage: null,
      maxage: null,
      minbath: null,
      maxbath: null,
    });

    this.fetchListings(qsParams);
  }

  render() {
    const {
      isLoading,
      totalInmuebles,
      inmuebles,
      currentPage,
      lastPage,
      from,
      to,
    } = this.state;

    const currentYear = new Date().getFullYear();

    const { location } = this.props;

    const isSale = qs.parse(location.search).biz === 1;

    return (
      <PageLayout>
        <Container>
          <Row className="mt-3">
            <Col>
              <Link to="/">&laquo; Volver</Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-3 text-center text-uppercase">Inmuebles</h3>
              <hr className="title-line" />
            </Col>
          </Row>
          {isLoading && (
            <Row>
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          )}
          {totalInmuebles === 0 && !isLoading && (
            <Row>
              <Col>
                <p>No se encontraron inmuebles</p>
              </Col>
            </Row>
          )}
          {totalInmuebles > 0 && (
            <>
              <Row className="mb-3">
                <Col>
                  <small>
                    {`Se encontraron ${totalInmuebles} inmueble(s), Mostrando del ${from} al ${to}`}
                  </small>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12" className="text-right">
                  <Button id="filterToggler">
                    Filtrar
                  </Button>
                  <UncontrolledButtonDropdown className="ml-3">
                    <DropdownToggle caret>Ordernar por</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={this.filterBy}
                        name="sortByPrice"
                        value="order=pricemax"
                      >
                        Mayor precio
                      </DropdownItem>
                      <DropdownItem
                        onClick={this.filterBy}
                        name="sortByPrice"
                        value="order=pricemin"
                      >
                        Menor precio
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
                <Col>
                  <UncontrolledCollapse
                    toggler="#filterToggler"
                  >
                    <Row>
                      <Col className="m-1">
                        {isSale ? (
                          <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                            <DropdownToggle color="light" caret>
                              Rango de precios:
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=0&pvmax=100000000"
                              >
                                Hasta $100MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=100000001&pvmax=200000000"
                              >
                                Desde $100MM a $200MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=200000001&pvmax=300000000"
                              >
                                Desde $200MM a $300MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=300000001&pvmax=400000000"
                              >
                                Desde $300MM a $400MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=400000001&pvmax=600000000"
                              >
                                Desde $400MM a $600MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=600000001&pvmax=800000000"
                              >
                                Desde $600MM a $800MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=800000001&pvmax=1000000000"
                              >
                                Desde $800MM a $1000MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=1000000001&pvmax=2000000000"
                              >
                                Desde $1000MM a $2000MM
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterBySalePrice"
                                value="pvmin=2000000001&pvmax=9999999999"
                              >
                                Mas de $2000MM
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        ) : (
                          <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                            <DropdownToggle color="light" caret>
                              Rango de precios:
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=0&pcmax=400000"
                              >
                                Hasta $400.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=400001&pcmax=800000"
                              >
                                Desde $400.001 a $800.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=800001&pcmax=1000000"
                              >
                                Desde $800.001 a $1.000.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=1000001&pcmax=1500000"
                              >
                                Desde $1.000.001 a $1.500.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=1500001&pcmax=2000000"
                              >
                                Desde $1.500.001 a $2.000.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=2000001&pcmax=2500000"
                              >
                                Desde $2.000.001 a $2.500.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=2500001&pcmax=3000000"
                              >
                                Desde $2.500.001 a $3.000.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=3000001&pcmax=4000000"
                              >
                                Desde $3.000.001 a $4.000.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=4000001&pcmax=5000000"
                              >
                                Desde $4.000.001 a $5.000.000
                              </DropdownItem>
                              <DropdownItem
                                onClick={this.filterBy}
                                name="filterByRentPrice"
                                value="pcmin=5000001&pcmax=50000000"
                              >
                                M&aacute;s de $5.000.000
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                        <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                          <DropdownToggle color="light" caret>
                            Habitaciones:
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=1"
                            >
                              1
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=2"
                            >
                              2
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=3"
                            >
                              3
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=4"
                            >
                              4
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=5"
                            >
                              5
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByRooms"
                              value="bedrooms=6"
                            >
                              6
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                          <DropdownToggle color="light" caret>
                            Garajes:
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByParking"
                              value="minparking=1&maxparking=1"
                            >
                              1
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByParking"
                              value="minparking=2&maxparking=2"
                            >
                              2
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByParking"
                              value="minparking=3&maxparking=3"
                            >
                              3
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByParking"
                              value="minparking=3&maxparking=99"
                            >
                              4 o m&aacute;s
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                          <DropdownToggle color="light" caret>
                            Antiguedad:
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByAge"
                              value={`minage=${currentYear - 5}&maxage=${currentYear}`}
                            >
                              Menos de 5 a&ntilde;os
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByAge"
                              value={`minage=${currentYear - 10}&maxage=${currentYear - 5}`}
                            >
                              Entre 5 y 10 a&ntilde;os
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByAge"
                              value={`minage=${currentYear - 20}&maxage=${currentYear - 10}`}
                            >
                              Entre 10 y 20 a&ntilde;os
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByAge"
                              value={`minage=${currentYear - 99}&maxage=${currentYear - 20}`}
                            >
                              M&aacute;s de 20 a&ntilde;os
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <UncontrolledButtonDropdown className="m-1 d-block d-lg-inline-block">
                          <DropdownToggle color="light" caret>
                            Ba&ntilde;os:
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByBathroom"
                              value="minbath=1&maxbath=1"
                            >
                              1
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByBathroom"
                              value="minbath=2&maxbath=2"
                            >
                              2
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByBathroom"
                              value="minbath=3&maxbath=3"
                            >
                              3
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByBathroom"
                              value="minbath=4&maxbath=4"
                            >
                              4
                            </DropdownItem>
                            <DropdownItem
                              onClick={this.filterBy}
                              name="filterByBathroom"
                              value="minbath=5&maxbath=99"
                            >
                              5 o m&aacute;s
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </Col>
                      <Col xs="12">
                        <hr />
                        <Button
                          color="info"
                          onClick={this.clearFilters}
                        >
                          Limpiar filtros
                        </Button>
                      </Col>
                    </Row>
                  </UncontrolledCollapse>
                </Col>
              </Row>
              <Row>
                {inmuebles.map(listing => (
                  <Col
                    key={listing.idpro}
                    className="mb-5"
                    xs="12"
                    sm="6"
                    lg="4"
                  >
                    <ListingCard listing={listing} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <Pagination size="lg">
                    {/* lastPage gets 1 added since range is 0 based */}
                    {range(1, lastPage + 1).map(item => (
                      <PaginationItem
                        key={item}
                        active={item === currentPage}
                      >
                        <PaginationLink
                          value={item}
                          onClick={this.goToPage}
                        >
                          {item}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </PageLayout>
    );
  }
}

InmueblesPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default InmueblesPage;
